class ErrorHandler {
  // TODO buscar por todo el codigo handleFailRequest hay que mejorarlo
  public static handleFailRequest(error: any): Record<string, unknown> {
    try {
      console.log('ERROR HANDLER ', error)
      const { response } = error
      const { data, status } = response
      return {
        success: data.success,
        status: status,
        message: data.data || data.message || 'An error was ocurred while operating',
      }
    } catch (error) {
      return {
        success: false,
        message: 'An error was courred while operating.',
      }
    }
  }
}
export default ErrorHandler
