import React from 'react'
import styled from 'styled-components/macro'

export const BodyVaultWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  position: relative;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '480px'};
  width: 100%;
  background: ${({ theme }) => theme.bg0};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 7px;
  margin-top: 2rem; //Margen superior de swap
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBodyVault({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyVaultWrapper {...rest}>{children}</BodyVaultWrapper>
}

