import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import AddressClaimModal from '../components/claim/AddressClaimModal'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { ApplicationModal } from '../state/application/actions'
import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
import CreateProposal from './CreateProposal'
import Earn from './Earn'
import Manage from './Earn/Manage'
import MigrateV2 from './MigrateV2'
import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
import Pool from './Pool'
import { PositionPage } from './Pool/PositionPage'
import PoolV2 from './Pool/v2'
import PoolFinder from './PoolFinder'
import PrivateSell from './PrivateSell'
import RemoveLiquidity from './RemoveLiquidity'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { OpenClaimAddressModalAndRedirectToSwap, RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import Vault from './Vault'
import Vote from './Vote'
import VotePage from './Vote/VotePage'
import { useLocation } from 'react-router-dom'
import HeaderPrivate from 'components/HeaderPrivate'
import { useEffect, useState } from 'react'
import ObexProjectsRequestHandler from './Vault/ObexProjectsRequestHandler'
//import Web3 from 'web3'
import { useActiveWeb3React } from 'hooks/web3'
import { parse } from 'path'
import Stack from './Stacking'
import { ChangePassword } from './Pool/changepassword'
/* eslint-disable */
const Web3 = require('web3')
const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 120px 16px 0px 16px;
  align-items: center;
  flex: 1;
  z-index: 1;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6rem 16px 16px 16px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 2;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function TopLevelModals() {
  const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
  const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  return <AddressClaimModal isOpen={open} onDismiss={toggle} />
}


export default function App() {
  const { account , chainId} = useActiveWeb3React()
  const [BalanceBlocked, setBalanceBlocked] = useState("")
  const [clients, setClients] = useState(Array());
  const [loadingBlocked, setloadingBlocked] = useState(false)

  const getBlockedTransactions = async (register_account: any) => {
    try{
      setClients([])
      setloadingBlocked(true)
      setBalanceBlocked("")
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/get_vaulted_transactions?account=${register_account}`)
      console.log(result) 
      const resultado = result["result"];
      const clientsList = resultado.sort((a: any, b: any) => a.amount > b.amount ? 1 : -1);

      let total = 0
      console.log("BLOQUEOS")
      clientsList.forEach((element: any) => {
        console.log(element.amount)
        total += element.amount
      });


      const result2 = await ObexProjectsRequestHandler.get(`/ws/util.py/getUSDGRAYcotization`)
      console.log("COTIZACION")
      console.log(result2["cotization"]/1000000000000000000)
      const price = result2["cotization"]/1000000000000000000
      setBalanceBlocked((total * price).toString())



      setClients(clientsList);
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }
    setloadingBlocked(false)
  }



  useEffect(() => {
    //if (approvalState === ApprovalState.PENDING) {
    //  setApprovalSubmitted(true)
    //}

    setTimeout(async () => { 
      try{
      const web3 = new Web3(Web3.givenProvider)
      const accounts = await web3.eth.getAccounts();

      getBlockedTransactions(accounts[0]);
      } catch{
        alert("You must connect Metamask")
      }
    }, 6000);

    console.log("CUENTA" + account)
    if (window.ethereum?.isMetaMask && account!=null ){
      getBlockedTransactions(account);
      console.log("LEYENDO ")
    } else {
      
    }
    if (typeof window.ethereum?.on !== "undefined") {
      window.ethereum.on('accountsChanged', async ()=>{
          //location.reload()
          const web3 = new Web3(Web3.givenProvider)
          const accounts = await web3.eth.getAccounts();
          getBlockedTransactions(accounts[0])
          console.log("LEYENDO")
        }
      )
      window.ethereum.on('chainChanged', async ()=>{
        //location.reload()
        const web3 = new Web3(Web3.givenProvider)
        const accounts = await web3.eth.getAccounts();
        getBlockedTransactions(accounts[0])
        console.log("LEYENDO")
      }
    )
    }
    console.log("CHAINID")
    console.log(chainId)
    //getEther()
    console.log("ETHER")
    //console.log(ETHERchange)


  }, [])


  return (
    <ErrorBoundary>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Web3ReactManager>
        <AppWrapper>
          <HeaderWrapper>
          {
            (useLocation().pathname=="/" ?
            <></>
            :
              <HeaderPrivate balance={BalanceBlocked} />
            )
          }
          </HeaderWrapper>
          <BodyWrapper>
            <Popups />
            <Polling />
            <TopLevelModals />
            <Switch>
              <Route exact strict path="/vote" component={Vote} />
              <Route exact strict path="/vote/:governorIndex/:id" component={VotePage} />
              <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
              <Route exact strict path="/uni" component={Earn} />
              <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />


              <Route exact strict path="/send" component={RedirectPathToSwapOnly} />

              <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
              <Route exact strict path="/swap" component={Swap} />

              <Route exact strict path="/pool/v2/find" component={PoolFinder} />
              <Route exact strict path="/pool/v2" component={PoolV2} />
              <Route exact strict path="/pool" component={Pool} />
              <Route exact strict path="/vault" component={Vault} />
              <Route exact strict path="/privatesell" component={PrivateSell} />
              <Route exact strict path="/stack" component={Stack} />
              <Route exact strict path="/pool/:tokenId" component={PositionPage} />
              
              <Route exact strict path="/changepassword/:tokenId" component={ChangePassword} />

              <Route exact strict path="/" component={RedirectToSwap} /> {/* PAGINA por Defecto*/}

              <Route exact strict path="/add/v2/:currencyIdA?/:currencyIdB?" component={RedirectDuplicateTokenIdsV2} />
              <Route
                exact
                strict
                path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                component={RedirectDuplicateTokenIds}
              />

              <Route
                exact
                strict
                path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                component={AddLiquidity}
              />

              <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
              <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />

              <Route exact strict path="/migrate/v2" component={MigrateV2} />
              <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} />

              <Route exact strict path="/create-proposal" component={CreateProposal} />
              <Route component={PrivateSell} />
            </Switch>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </Web3ReactManager>
    </ErrorBoundary>
  )
}
