import axios from 'axios'
import React from "react";
import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Token, TradeType } from '@uniswap/sdk-core'
import SwitchSelector from "react-switch-selector";
import { Trade as V2Trade } from '@uniswap/v2-sdk'
import { Trade as V3Trade } from '@uniswap/v3-sdk'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import { AdvancedSwapDetails } from 'components/swap/AdvancedSwapDetails'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import VaultHeader from 'components/swap/VaultHeader'
import { MouseoverTooltip, MouseoverTooltipContent } from 'components/Tooltip'
import JSBI from 'jsbi'
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown, ArrowLeft, CheckCircle, HelpCircle, Info } from 'react-feather'
import ReactGA from 'react-ga'
import { Link, RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components/macro'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonConfirmed, ButtonError, ButtonGray, ButtonLight, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import Column, { AutoColumn, VaultColumn, ListColumn } from '../../components/Column'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CurrencyLogo from '../../components/CurrencyLogo'
import Loader from '../../components/Loader'
import Row, { AutoRow, RowFixed } from '../../components/Row'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, Dots, SwapCallbackError, Wrapper, WrapperExchange } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import TradePrice from '../../components/swap/TradePrice'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import TokenWarningModal from '../../components/TokenWarningModal'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { V3TradeState } from '../../hooks/useBestV3Trade'
import useENSAddress from '../../hooks/useENSAddress'
import { useERC20PermitFromTrade, UseERC20PermitState } from '../../hooks/useERC20Permit'
import useIsArgentWallet from '../../hooks/useIsArgentWallet'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import { useUSDCValue } from '../../hooks/useUSDCPrice'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useActiveWeb3React } from '../../hooks/web3'
import { useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './estilos.css'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSingleHopOnly } from '../../state/user/hooks'
import { HideSmall, LinkStyledButton, TYPE } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { getTradeVersion } from '../../utils/getTradeVersion'
import { isTradeBetter } from '../../utils/isTradeBetter'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { useTokenBalance } from 'state/wallet/hooks'
import { UNI } from '../../constants/tokens'
import ObexProjectsRequestHandler from './ObexProjectsRequestHandler'
import { getContract } from 'utils'
import getLibrary from 'utils/getLibrary'
import { getNetworkLibrary } from 'connectors'
import { ethers, ContractFactory } from 'ethers'
import { DateTime } from 'luxon'
import AppBodyVault from 'pages/AppBodyVault'
import AppListVault from 'pages/ListBody'
import Input from 'components/NumericalInput'
import EmailInputPanel from 'components/EmailInputPanel'

/* eslint-disable */
const HDWalletProvider = require('@truffle/hdwallet-provider');
const Web3 = require('web3')
const StyledInfo = styled(Info)`
  opacity: 0.4;
  color: ${({ theme }) => theme.text1};
  height: 16px;
  width: 16px;
  :hover {
    opacity: 0.8;
  }
`
const panels = ["","","","","rinkeby."]
export default function Vault({ history }: RouteComponentProps) {
  const [startDate, setStartDate] = useState(new Date())
  //const { account } = useActiveWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  const [blockAmount, setBlockAmount] = useState(0)

  const today = new Date()

  const [sendVault, setSendVault] = useState(false)

  const [receptor, setReceptor] = useState("")

  const [vaulting, setVaulting] = useState(false)

  const setExito = (status: Boolean) =>{
    if (status) setVaulting(false)
  }

  const setError = (status: Boolean) =>{
    if (status) setVaulting(false)
  }

  const options = [
    { label: 'Self', value: true },
    { label: 'Send', value: false }
];
const onChange = (newValue: any) => {
  setSendVault(!newValue)
  console.log(newValue);
};
const initialSelectedIndex = options.findIndex(({value}) => value === true);

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  const handledate = (data: any) => {
    setStartDate(data)
  }

  const onChangeReceptor = (data: any) => {
    setReceptor(data)
  }

  async function getCurrentGasPrices() {
    let response = await axios.get('https://ethgasstation.info/json/ethgasAPI.json');
    let prices = {
      low: response.data.safeLow / 10,
      medium: response.data.average / 10,
      high: response.data.fast / 10
    };
    return prices;
}

  const vaultTokens = async (data: any, amount: any, time: any, account: any, unblockDate: any, currency: any) => {
    let td = new Date().toISOString().slice(0, 10)
    const transDate = td
    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/vault?p_key=${data}&amount=${amount}&time=${time}&network=${chainId}&account=${account}&date=${transDate}&unblock=${unblockDate}&currency=${currency}&email=${receptor}`)
      console.log("RESULTADO FINAL")
      console.log(result)
      setExito(false)
      setExito(false)
      if (result["status"]){
        setExito(true)
        handleClearInput()
        setBlockAmount(0)
        setSendVault(false)
        setReceptor("")
      } else {
        setError(true)
      }
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }
  }

  const registerVault = async (register_account: any, Amount: any, time: any, abi: any, vault_address: any, result_hash: any, unblockDate: any, currency: any ) => {
    try{
      let td = new Date().toISOString().slice(0, 10)
      const transDate = td
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/register_vault?account=${register_account}&amount=${Amount}&time=${time}&vault_address=${vault_address}&abi=${JSON.stringify(abi)}&transaction_hash=${result_hash}&date=${transDate}&unblock=${unblockDate}&currency=${currency}&network=${chainId}`)
      console.log(result)
      setExito(true)
      if (result["status"]) {
        setReceptor("")
        setBlockAmount(0)
        setSendVault(false)
        handleClearInput()
        await getBlockedTransactions(account);
      }
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }

  }

  const createAccount = async (amount: any, time: any, currency: any) => {
    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/create_ethereum_account?network=${chainId}`)
      if (result["status"]){
        let private_key_wallet = result["PK"].substring(2)
        console.log(private_key_wallet)
        const web3 = new Web3(Web3.givenProvider)
        const saldo = await web3.eth.getBalance(result["ACCOUNT"])
        console.log(saldo)

        const provider = new ethers.providers.Web3Provider(web3.currentProvider)
        const signer = provider.getSigner()

        let prices = await getCurrentGasPrices()

        const transaction = {
          nonce: signer.getTransactionCount(),
          gasLimit: 1500000,
          to: result["ACCOUNT"],
          value: amount + ((prices.high * 1000000000) * 2000),//ethers.utils.parseEther(amount),
          chainId: chainId // ropsten
          }
          let response = await signer.sendTransaction(transaction)
          console.log("RESULTADO")
          console.log(response)
          /*
          let  saldof = await web3.eth.getBalance(result["ACCOUNT"])
          while (saldof==0){
          saldof = await web3.eth.getBalance(result["ACCOUNT"])
          console.log("saldo")
          console.log(saldof)
          }*/
          await vaultTokens(private_key_wallet, amount, time, result["ACCOUNT"],startDate.toISOString().slice(0, 10),currency)
      }
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }

  }

  const getBlockedTransactions = async (register_account: any) => {
    try{
      const result = await ObexProjectsRequestHandler.get(`/ws/util.py/get_vaulted_transactions?account=${register_account}`)
      console.log(result) 
      const resultado = result["result"];
      const clientsList = resultado.sort((a: any, b: any) => a.amount > b.amount ? 1 : -1);
      setClients(clientsList);
    }catch (error) {
      console.error('ERROR SENDING VAULT ', error)
    }
  }

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get version from the url
  const toggledVersion = useToggledVersion()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const {
    v2Trade,
    v3TradeState: { trade: v3Trade, state: v3TradeState },
    toggledTrade: trade,
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(toggledVersion)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const fiatValueInput = useUSDCValue(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDCValue(parsedAmounts[Field.OUTPUT])
  const priceImpact = computeFiatValuePriceImpact(fiatValueInput, fiatValueOutput)
  const { library, account, chainId } = useActiveWeb3React()
  //const acct = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const uniBalance: CurrencyAmount<Token> | undefined = useTokenBalance(account ?? undefined, uni)
  const FixedContainer = styled.div`
    width: 100%;
    height: 4em;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.bg1};
    opacity: 0.95;
    display: flex;
    align-items: center;
    justify-content: start;
    z-index: 2;
    padding-left: 1em;
  `
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const [clients, setClients] = useState(Array());

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
      setBlockAmount(parseFloat(value))
      if (value == '') setBlockAmount(0)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/swap/')
  }, [history])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: V2Trade<Currency, Currency, TradeType> | V3Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const routeNotFound = !trade?.route
  const isLoadingRoute = toggledVersion === Version.v3 && V3TradeState.LOADING === v3TradeState

  // check whether the user has approved the router on the input token
  const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const {
    state: signatureState,
    signatureData,
    gatherPermitSignature,
  } = useERC20PermitFromTrade(trade, allowedSlippage)

  const handleApprove = useCallback(async () => {
    if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
      try {
        await gatherPermitSignature()
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback()
        }
      }
    } else {
      await approveCallback()
    }
  }, [approveCallback, gatherPermitSignature, signatureState])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
    if (window.ethereum?.isMetaMask && account!=null ){
      getBlockedTransactions(account);
    } else {
      getBlockedTransactions("All");
    }
    console.log("ChannelID ")
    console.log(chainId)
  }, [approvalState, approvalSubmitted])

  const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient,
    signatureData
  )

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade),
            singleHopOnly ? 'SH' : 'MH',
          ].join('/'),
        })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [
    swapCallback,
    priceImpact,
    tradeToConfirm,
    showConfirm,
    recipient,
    recipientAddress,
    account,
    trade,
    singleHopOnly,
  ])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on the greater of fiat value price impact and execution price impact
  const priceImpactSeverity = useMemo(() => {
    const executionPriceImpact = trade?.priceImpact
    return warningSeverity(
      executionPriceImpact && priceImpact
        ? executionPriceImpact.greaterThan(priceImpact)
          ? executionPriceImpact
          : priceImpact
        : executionPriceImpact ?? priceImpact
    )
  }, [priceImpact, trade])

  const isArgentWallet = useIsArgentWallet()

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isArgentWallet &&
    !swapInputError &&
    (approvalState === ApprovalState.NOT_APPROVED ||
      approvalState === ApprovalState.PENDING ||
      (approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )



  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
  }, [maxInputAmount, onUserInput])

  const handleClearInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, "0")
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const swapIsUnsupported = useIsSwapUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const priceImpactTooHigh = priceImpactSeverity > 3 && !isExpertMode

  return (
    <>
      <TokenWarningModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokens={importTokensNotInDefault}
        onConfirm={handleConfirmTokenWarning}
        onDismiss={handleDismissTokenWarning}
      />
      <NetworkAlert />
      <RowFixed>
      <VaultColumn>
      <AppBodyVault>
        <VaultHeader allowedSlippage={allowedSlippage} />
        <WrapperExchange id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />
          <hr style={{ transform: 'translate(-4%)', width: '108.5%' }}></hr>
          <AutoColumn gap={'md'}>
            <div style={{ display: 'relative', marginTop: '0px' }}>
              <CurrencyInputPanel
                title="Amount:"
                label={
                  independentField === Field.OUTPUT && !showWrap ? <Trans>From (at most)</Trans> : <Trans>From</Trans>
                }
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT]}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={fiatValueInput ?? undefined}
                onCurrencySelect={handleInputSelect}
                otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases={true}
                id="swap-currency-input"
              />
            </div>
            <FixedContainer>Multisign Block</FixedContainer>
            <FixedContainer>
              <AutoRow style={{ minWidth: '50%;' }}>Block until date</AutoRow>
              <DatePicker selected={startDate} onChange={(e) => handledate(e)} className="boton-fecha" />
            </FixedContainer>
            {recipient !== null && !showWrap && false ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    <Trans>- Remove send</Trans>
                  </LinkStyledButton>
                </AutoRow>
               <AddressInputPanel id="recipient" value={recipient || ""} onChange={onChangeRecipient} />
              </>
            ) : null}

            {showWrap ? null : (
              <Row style={{ justifyContent: !trade ? 'center' : 'space-between' }}>
                <RowFixed>
                  {[V3TradeState.VALID, V3TradeState.SYNCING, V3TradeState.NO_ROUTE_FOUND].includes(v3TradeState) &&
                    (toggledVersion === Version.v3 && isTradeBetter(v3Trade, v2Trade) ? (
                      <BetterTradeLink version={Version.v2} otherTradeNonexistent={!v3Trade} />
                    ) : toggledVersion === Version.v2 && isTradeBetter(v2Trade, v3Trade) ? (
                      <BetterTradeLink version={Version.v3} otherTradeNonexistent={!v2Trade} />
                    ) : (
                      toggledVersion === Version.v2 && (
                        <ButtonGray
                          width="fit-content"
                          padding="0.1rem 0.5rem 0.1rem 0.35rem"
                          as={Link}
                          to="/swap"
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '24px',
                            lineHeight: '120%',
                            marginLeft: '0.75rem',
                          }}
                        >
                          <ArrowLeft color={theme.text3} size={12} /> &nbsp;
                          <TYPE.main style={{ lineHeight: '120%' }} fontSize={12}>
                            <Trans>
                              <HideSmall>Back to </HideSmall>
                              V3
                            </Trans>
                          </TYPE.main>
                        </ButtonGray>
                      )
                    ))}

                  {toggledVersion === Version.v3 && trade && isTradeBetter(v2Trade, v3Trade) && (
                    <ButtonGray
                      width="fit-content"
                      padding="0.1rem 0.5rem"
                      disabled
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: '24px',
                        opacity: 0.8,
                        marginLeft: '0.25rem',
                      }}
                    >
                      <TYPE.black fontSize={12}>
                        <Trans>V3</Trans>
                      </TYPE.black>
                    </ButtonGray>
                  )}
                </RowFixed>
                {trade ? (
                  <RowFixed>
                    <TradePrice
                      price={trade.executionPrice}
                      showInverted={showInverted}
                      setShowInverted={setShowInverted}
                    />
                    <MouseoverTooltipContent
                      content={<AdvancedSwapDetails trade={trade} allowedSlippage={allowedSlippage} />}
                    >
                      <StyledInfo />
                    </MouseoverTooltipContent>
                  </RowFixed>
                ) : null}
              </Row>
            )}

            <div>
              <RowFixed style={{marginBottom:'1em', minWidth:'10em'}}>
            <SwitchSelector
                          onChange={onChange}
                          options={options}
                          initialSelectedIndex={initialSelectedIndex}
                          backgroundColor={"#eb7400"}
                          fontColor={"#f5f6fa"}
                      />
              </RowFixed>
              {swapIsUnsupported ? (
                <ButtonPrimary disabled={true}>
                  <TYPE.main mb="4px">
                    <Trans>Unsupported Asset</Trans>
                  </TYPE.main>
                </ButtonPrimary>
              ) : !account ? (
                <ButtonLight onClick={toggleWalletModal}>
                  <Trans>Connect Wallet</Trans>
                </ButtonLight>
              ) : showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP ? (
                      <Trans>Wrap</Trans>
                    ) : wrapType === WrapType.UNWRAP ? (
                      <Trans>Unwrap</Trans>
                    ) : null)}
                </ButtonPrimary>
              ) : routeNotFound && userHasSpecifiedInputOutput ? (
                <GreyCard style={{ textAlign: 'center' }}>
                  <TYPE.main mb="4px">
                    {isLoadingRoute ? (
                      <Dots>
                        <Trans>Loading</Trans>
                      </Dots>
                    ) : singleHopOnly ? (
                      <Trans>Insufficient liquidity for this trade. Try enabling multi-hop trades.</Trans>
                    ) : (
                      <Trans>Insufficient liquidity for this trade.</Trans>
                    )}
                  </TYPE.main>
                </GreyCard>
              ) : showApproveFlow ? (
                <AutoRow style={{ flexWrap: 'nowrap', width: '100%' }}>
                  <AutoColumn style={{ width: '100%' }} gap="12px">
                    <ButtonConfirmed
                      onClick={handleApprove}
                      disabled={
                        approvalState !== ApprovalState.NOT_APPROVED ||
                        approvalSubmitted ||
                        signatureState === UseERC20PermitState.SIGNED
                      }
                      width="100%"
                      altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                      confirmed={
                        approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED
                      }
                    >
                      <AutoRow justify="space-between" style={{ flexWrap: 'nowrap' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <CurrencyLogo
                            currency={currencies[Field.INPUT]}
                            size={'20px'}
                            style={{ marginRight: '8px', flexShrink: 0 }}
                          />
                          {/* we need to shorten this string on mobile */}
                          {approvalState === ApprovalState.APPROVED || signatureState === UseERC20PermitState.SIGNED ? (
                            <Trans>You can now trade {currencies[Field.INPUT]?.symbol}</Trans>
                          ) : (
                            <Trans>Allow the Uniswap Protocol to use your {currencies[Field.INPUT]?.symbol}</Trans>
                          )}
                        </span>
                        {approvalState === ApprovalState.PENDING ? (
                          <Loader stroke="white" />
                        ) : (approvalSubmitted && approvalState === ApprovalState.APPROVED) ||
                          signatureState === UseERC20PermitState.SIGNED ? (
                          <CheckCircle size="20" color={theme.green1} />
                        ) : (
                          <MouseoverTooltip
                            text={
                              <Trans>
                                You must give the Uniswap smart contracts permission to use your{' '}
                                {currencies[Field.INPUT]?.symbol}. You only have to do this once per token.
                              </Trans>
                            }
                          >
                            <HelpCircle size="20" color={'white'} style={{ marginLeft: '8px' }} />
                          </MouseoverTooltip>
                        )}
                      </AutoRow>
                    </ButtonConfirmed>

                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          })
                        }
                      }}
                      width="100%"
                      id="swap-button"
                      disabled={
                        !isValid ||
                        (approvalState !== ApprovalState.APPROVED && signatureState !== UseERC20PermitState.SIGNED) ||
                        priceImpactTooHigh
                      }
                      error={isValid && priceImpactSeverity > 2}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        segunda
                        {priceImpactTooHigh ? (
                          <Trans>High Price Impact</Trans>
                        ) : priceImpactSeverity > 2 ? (
                          <Trans>Swap Anyway</Trans>
                        ) : (
                          <Trans>Gray Ghost Token</Trans>
                        )}
                      </Text>
                    </ButtonError>
                  </AutoColumn>
                </AutoRow>
              ) : (
                <ButtonError
                  onClick={async () => {
                    formattedAmounts[Field.INPUT]="0"
                    
                    
                    setVaulting(true)
                    let currency = currencies[Field.INPUT] || {"symbol":"NO"}
                    let actual = new Date()                    
                    let dif = startDate.getTime() - actual.getTime();

                    if (!sendVault){
                        const web3 = new Web3(Web3.givenProvider);
                        const accounts = await web3.eth.getAccounts();
                        const result = await ObexProjectsRequestHandler.get(`/ws/util.py/compile_smartcontract`)
                        const abi = result["ABI"]
                        const bc = result["BYTECODE"]

                        const provider = new ethers.providers.Web3Provider(web3.currentProvider)
                        const signer = provider.getSigner()
                        const factory = new ContractFactory(abi, "0x" + bc, signer);
                        const contracto = await factory.deploy()
                        const CONTRACT_ADDRESS = contracto["address"]
                        const MyContract2 = await factory.attach(CONTRACT_ADDRESS)

                        let overrides = {
                          nonce: signer.getTransactionCount(),
                          gasLimit: 1500000,
                          value: ethers.utils.parseEther(blockAmount.toString()) // * 1000000000000000000
                        };

                        let data = await MyContract2.populateTransaction.payIn(dif, overrides)
                        let response = await signer.sendTransaction(data)

                        registerVault(
                            accounts[0], 
                            blockAmount * 1000000000000000000, 
                            dif, 
                            abi, 
                            CONTRACT_ADDRESS, 
                            response["hash"],
                            startDate.toISOString().slice(0, 10),
                            currency["symbol"]
                            )
                    } else {

                      console.log("ENVIO DE SALDO BLOQUEADO A DESTINATARIO " + receptor)
                      createAccount(blockAmount * 1000000000000000000, dif, currency["symbol"])

                    }
                    
                  }}
                  id="swap-button"
                  disabled={(!(blockAmount != 0 && today.toDateString() != startDate.toDateString()) || !(!sendVault || !(Boolean(receptor.length < 1 || !receptor.includes('@') || !receptor.includes('.'))))) || vaulting } 
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {(blockAmount == 0 || today.toDateString() == startDate.toDateString()) ? swapInputError : <Trans>Block Tokens</Trans>}
                    {vaulting ?
                    <div style={{marginLeft:'1em', display: 'inline'}}>
                    <Loader stroke="black"></Loader>
                    </div>
                    :
                    <div></div>
                    }
                  </Text>
                </ButtonError>
              )}

              <RowFixed style={{marginTop: '1em'}}>
              </RowFixed>
              {sendVault &&
              <EmailInputPanel id="recipient-email" value={receptor || ""} onChange={onChangeReceptor} />
              }
              {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            </div>
          </AutoColumn>
        </WrapperExchange>

      </AppBodyVault>
      </VaultColumn>
      <ListColumn>
      <AppListVault>
        <WrapperExchange id="swap-page-2">
        {window.ethereum?.isMetaMask && account!=null   ? 
          <div style={{backgroundColor: 'lightgray', padding: '10px', borderRadius: '5px'}}>
          Blocked tokens for {account} 
          </div>  :
          <div style={{backgroundColor: 'lightgray', padding: '10px', borderRadius: '5px'}}>
            All blocked tokens
          </div>
          }
        <RowFixed style={{padding: '10px', fontSize: '11px'}}>
          <Column style={{width:'8.5em'}}>
          Unblock Date
          </Column>
          <Column style={{marginLeft: '10px', width: '40em', textAlign: 'start' }}>
          Block Address
          </Column>
          <Column style={{marginLeft: '15px', width: '10em', textAlign: 'end' }}>
          Amount Blocked
          </Column>
          <Column style={{marginLeft: '10px', width: '5em', textAlign: 'end' }}>
          Currency
          </Column>
        </RowFixed>
        {clients.map(client => { 
          let dd = `https://${panels[client.network || 0]}etherscan.io/address/${client.vault_address}` 
            return (
              <RowFixed style={{padding: '10px'}}>
                <Column style={{maxWidth:'5.5em', minWidth:'5.5em'}}>
                {client.unblock}
                </Column>
                <Column style={{marginLeft: '10px', minWidth: '24.8em', maxWidth: '24.8em' ,textAlign: 'start' }}>
                <a style={{color: 'black', paddingLeft:'5px'}} href={dd} target="blank">{client.vault_address}</a>
                </Column>
                <Column style={{marginLeft: '10px', minWidth: '10em', textAlign: 'end' }}>
                {(client.amount)} wei
                </Column>
                <Column style={{marginLeft: '10px', width: '3em', textAlign: 'end' }}>
                {(client.currency)}
                </Column>
              </RowFixed>
            )
            })} 
        </WrapperExchange>
      </AppListVault>
      </ListColumn>
</RowFixed>
      <SwitchLocaleLink />
      {!swapIsUnsupported ? null : (
        <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
      )}
    </>
  )
}
