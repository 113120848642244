import RequestHandler from './RequestHandler'
class ObexProjectsRequestHandler {
  public static urlServer = 'https://docking.101obex.mooo.com'
  public static port = 443

  private static url = `${ObexProjectsRequestHandler.urlServer}:${ObexProjectsRequestHandler.port}`

  public static async get(path: string, params?: object, disableCache: boolean = false): Promise<any> {
    try {
      const url = `${this.url}${path}`
      console.log("URL "+ url)
      const headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz' }
      const result = await RequestHandler.get(url, params, headers)
      return result
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async post(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`
      const headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz' }
      const result = await RequestHandler.post(url, payload, headers)
      return result
    } catch (error) {
      console.error('error in obex request handler(get)', error)
    }
  }

  public static async put(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`
      const headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz' }
      const result = await RequestHandler.put(url, payload, headers)
      return result
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async patch(path: string, payload?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`
      const headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz' }
      const result = await RequestHandler.patch(url, payload, headers)
      return result
    } catch (error) {
      console.error('error in obex request handler(put)', error)
    }
  }

  public static async delete(path: string, params?: object): Promise<any> {
    try {
      const url = `${this.url}${path}`
      const headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz' }
      const result = await RequestHandler.delete(url, params, headers)
      return result
    } catch (error) {
      console.error('error in obex request handler(delete)', error)
    }
  }
}

export default ObexProjectsRequestHandler